import cloneDeep from 'lodash/cloneDeep';
import { Layouts, Images } from '@/components/ui/charts/plotly/PlotlyHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';
import { Enums } from '@flightscope/baseball-stats';

export default {
  name: 'ReleasePointChart',

  extends: ChartMiddlewareVue,

  data() {
    return {
      adjustRanges: true,
      chartType: ChartTypeEnum.RELEASE_POINT.type,
      plotlyLayoutExtended: Object.assign(cloneDeep(Layouts.scatterChart), {
        xaxis:{
          ...Layouts.scatterChart.xaxis,
          zerolinewidth: 1.5,
        },
      }),
    };
  },

  methods: {
    customMapping(converted) {
      this.plotlyData = converted.map(this.mapDefaultTraceSettings);

      let symbol = this.unitSymbolX(this.unitSystem.system);
      this.plotlyLayoutExtended.title.text = this.$vuetify.lang.t('$vuetify.reports.releasePointChart');
      this.plotlyLayoutExtended.xaxis.title = `${this.xAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.yaxis.title = `${this.yAxisTitle}, ${symbol}`;

      const image = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key ?
        Images.releasePointPlayer
        : Images.releasePointPlayerSoftball;

      let convertedReleasePointPlayer = {
        ...{},
        ...image,
        x: this.convertToCurrent(image.x, this.xAxisConversion).value,
        sizex: this.convertToCurrent(image.sizex, this.xAxisConversion).value,
        sizey: this.convertToCurrent(image.sizey, this.yAxisConversion).value,
        sizing: 'fill',
      };

      this.plotlyLayoutExtended.images = [convertedReleasePointPlayer];

      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);
    },
  },
};
